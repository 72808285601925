import React, { useState, useEffect } from 'react';
import { signInWithGoogle, getUserRole, signOut } from '../components/firebase.js';
import LanguageSwitch from '../components/LanguageSwitch.js';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { useLanguage } from '../contexts/LanguageContext';
import '../styles/Topbar.css';
import defaultUserIcon from '../icons/icons8-male-user-100.png';
import hoursIcon from '../icons/icons8-clock-100.png';
import accessibilityIcon from '../icons/icons8-accessibility-100.png';
import kbmIcon from '../images/1x/Asset 6.png';
import text from '../text';
import AccessibilityMenu from './AccessibilityMenu';

const Topbar = ({ onLogin, onShowWelcome, user }) => {
  const [isManager, setIsManager] = useState(false);
  const [isAccessibilityMenuOpen, setIsAccessibilityMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false); // State to manage user menu visibility
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const { language } = useLanguage();

  useEffect(() => {
    if (user) {
      getUserRole(user).then((role) => {
        setIsManager(role === 'manager');
      });
    } else {
      setIsManager(false);
    }
  }, [user]);

  const toggleAccessibilityMenu = () => {
    setIsAccessibilityMenuOpen(!isAccessibilityMenuOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleLogin = () => {
    signInWithGoogle()
      .then((user) => {
        onLogin(user);
        setIsUserMenuOpen(false); // Close menu after login
      })
      .catch((error) => {
        console.error('Error during login:', error);
      });
  };

  const handleLogout = () => {
    signOut()
      .then(() => {
        setIsManager(false);
        navigate('/');
        setIsUserMenuOpen(false); // Close menu after logout
      })
      .catch((error) => {
        console.error('Error during sign out:', error);
      });
  };

  const handleNavigate = (path) => () => {
    navigate(path);
    setIsUserMenuOpen(false); // Close menu after navigation
  };

  // Conditionally render LanguageSwitch based on the current path
  const shouldShowLanguageSwitch = !['/register', '/quiz', '/pickup','/management','/clientList','/ledger','/test_results','/guns'].includes(location.pathname);

  return (
    <div className="topbar">
      <div className="topbar-title2">
        <button className="home-button2" onClick={handleNavigate('/')}>
          <img src={kbmIcon} alt="Shooting Range" className="shooting-range-icon" />
          <h3>{text[language].gunRangeTitle}</h3>
        </button>
      </div>
      <div className="topbar-buttons">
        {isManager && <button className='manager-button' onClick={handleNavigate('/test_results')}>תוצאות מבחנים</button>}
        {isManager && <button className='manager-button' onClick={handleNavigate('/gun-collection')}> איסוף אקדחים</button>}
        {isManager && <button className='manager-button' onClick={handleNavigate('/shooter-log')}>יומן יורים</button>}
        {isManager && <button className='manager-button' onClick={handleNavigate('/hitting-log')}>יומן פגיעות</button>}
        {isManager && <button className='manager-button' onClick={handleNavigate('/clientList')}>ניהול נרשמים</button>}
        {shouldShowLanguageSwitch && <LanguageSwitch />} {/* Conditionally render LanguageSwitch */}
        <img src={hoursIcon} alt="opening-hours" className="opening-hours" onClick={onShowWelcome} />
        <img src={accessibilityIcon} alt="accessibility-menu" className="accessibility-icon" onClick={toggleAccessibilityMenu} title='accessibility' />

        <div className="user-menu">
          <img
            src={user?.photoURL || defaultUserIcon}
            alt="User"
            className="user-icon"
            onClick={toggleUserMenu}
          />
          {isUserMenuOpen && (
            <div className="dropdown">
              {user ? (
                <>
                  <p>{user.displayName ? 'Hi, ' + user.displayName : "Hello User"}</p>
                  <button onClick={handleNavigate('/')}>{text[language].home}</button>
                  <button onClick={handleNavigate('/quiz')}>מבחן עיוני</button>
                  <button onClick={handleNavigate('/register')}>{text[language].register}</button>
                  <button onClick={handleNavigate('/pickup')}>טופס קבלת אקדח</button>
                  {isManager && <span>____________</span>}
                  {isManager && <button onClick={handleNavigate('/management')}>ניהול אתר</button>}
                  {isManager && <button onClick={handleNavigate('/clientList')}>ניהול נרשמים</button>}
                  {isManager && <button onClick={handleNavigate('/ledger')}>יומן יורים</button>}
                  {isManager && <button onClick={handleNavigate('/test_results')}>תוצאות מבחנים</button>}
                  {isManager && <button onClick={handleNavigate('/ocr')}>OCR</button>}
                  {isManager && <span>____________</span>}
                  <button onClick={handleLogout}>{text[language].logout}</button>
                </>
              ) : (
                <>
                <button onClick={handleLogin}>{text[language].loginButton}</button>
                <button onClick={handleNavigate('/')}>{text[language].home}</button>
                <button onClick={handleNavigate('/quiz')}>מבחן עיוני</button>
                <button onClick={handleNavigate('/register')}>{text[language].register}</button>
                <button onClick={handleNavigate('/pickup')}>טופס קבלת אקדח</button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isAccessibilityMenuOpen && <AccessibilityMenu onClose={toggleAccessibilityMenu} />}
    </div>
  );
};

export default Topbar;
