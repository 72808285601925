import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import HealthDeclaration from '../pdfs/training_health-declaration-extended-compressed.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl,db } from '../components/firebase'; // Import the upload function
import { doc, getDoc } from "firebase/firestore";


const generateHealthDeclaration = async (formData) => {
  const existingPdfUrl = HealthDeclaration;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  // Load a font that supports Hebrew characters
  const fontUrl = open_sans_font;
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const fullName = formData.fullName || 'שם משתמש';
  const teudatZehut = formData.teudatZehut || '123456789';
  const currentDate = new Date().toLocaleDateString('he-IL');
  let signatureUrl = null;
  try {
    const userDocRef = doc(db, 'users', teudatZehut);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      signatureUrl = userDoc.data().signatureUrl;
    } else {
      console.warn(`No user found with Teudat Zehut: ${teudatZehut}`);
    }
  } catch (error) {
    console.error('Error fetching user data from Firestore:', error);
  }
  // Position the fields on the PDF (adjust positions as needed)
  firstPage.drawText(fullName, { x: 320, y: 625, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 160, y: 625, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(currentDate, { x: 485, y: 180, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(fullName, { x: 310, y: 180, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });
  if(formData.healthDeclarationConfirm){
    firstPage.drawText('X', { x: 480, y: 580, size: 16, font: hebrewFont, color: rgb(0, 0, 0.5) });

  }
  if(formData.healthUpdateConfirm){
    firstPage.drawText('X', { x: 480, y: 510, size: 16, font: hebrewFont, color: rgb(0, 0, 0.5) });
  }
    


  // Add the signature
  if (signatureUrl) {
    const signatureImage = await pdfDoc.embedPng(signatureUrl);
    firstPage.drawImage(signatureImage, { x: 100, y: 180, width: 150*1.1, height: 50*1.1 });
  }


  const pdfBytes = await pdfDoc.save();
  // Create a blob from the PDF bytes
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `trainCertificate_${teudatZehut}_${fullName}_${currentDate}.pdf`;
  downloadLink.click();



  // const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'health_declarations', `health_declaration_${teudatZehut}_${fullName}_${currentDate}`);
  
  // return downloadUrl;
};

export default generateHealthDeclaration;
