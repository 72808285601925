import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { doc, setDoc, query, where, getDocs, collection, getDoc } from "firebase/firestore";
import { db, auth, addNonLoggedMember, addRegistration, generateRangeId, addSubscriber } from './firebase';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import HealthDeclaration from './subForms/HealthDeclaration';
import InWallSafe from './subForms/InWallSafe';
import OrganizationalTraining from './subForms/OrganizationalTraining';
import '../styles/Register.css';
import '../styles/SignaturePad.css';
import { Helmet } from 'react-helmet';  // Import Helmet
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import { logError } from '../components/firebase.js';
import { useDropzone } from 'react-dropzone';
import ProgressBar from './ProgressBar';
import warning_icon from '../icons/icons8-warning-100.png';
import Modal from './Modal';  // Import the Modal component



const Register = () => {
  const { language } = useLanguage();
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
  const [uploadedFileName, setUploadedFileName] = useState(''); // State to store file name
  const [modalOpen, setModalOpen] = useState(false);  // State for modal visibility
  const [modalTitle, setModalTitle] = useState('');  // State for modal title
  const [modalMessage, setModalMessage] = useState('');  // State for modal message

  
  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setLoading(false);
        alert('נראה שהייתה שגיאה - אנא ודאו שמילאתם את כל הסעיפים ונסו שנית');
      }, 12000); // 15 seconds
    }

    // Cleanup the timer if the component unmounts or loading changes
    return () => clearTimeout(timer);
  }, [loading]);

  const sigCanvas = useRef({});
  const [formData, setFormData] = useState({
    rangeId: '',
    registrationType: '',
    fullName: '',
    phoneNumber: '',
    teudatZehut: '',
    gunLicenseId: '',
    imprintOnPistol: '',
    licenseValidity: '',
    email: '',
    conditionalApprovalFileUrl:'',
    healthDeclarationConfirm: false,
    healthUpdateConfirm: false,
    newsletter: false,
    street: '',
    homeNumber: '',
    city: '',
    zip: '',
    pistolManufacturer: '',
    pistolModel: '',
    otherOption: '',
    quizResult: 'טרם נבחן',
  });
  const navigate = useNavigate();

  
  const handleModalConfirm = () => {
    setModalOpen(false);
    navigate('/');  // Navigate to the home route
  };

  const renderSubForm = () => {
    switch (formData.registrationType) {
      case 'הכשרה ראשונית':
        return (
          <div className="subForm-container">
            <HealthDeclaration formData={formData} setFormData={setFormData} />
            <InWallSafe formData={formData} handleChange={handleChange} />
            <OrganizationalTraining formData={formData} handleChange={handleChange} />
          </div>
        );
      case 'הכשרה ארגונית':
        return (
          <div className="subForm-container">
            <HealthDeclaration formData={formData} setFormData={setFormData} />
            <OrganizationalTraining formData={formData} handleChange={handleChange} />
          </div>
        );
      case 'חידוש רישיון':
      case 'רענון תקופתי':
        return (
          <div className="subForm-container">
            <HealthDeclaration formData={formData} setFormData={setFormData} />
            <OrganizationalTraining formData={formData} handleChange={handleChange} />

          </div>
        );
      case 'ירי חופשי':
      case 'בדיקת תקינות נשק':
        return (
          <div className="subForm-container">
            <HealthDeclaration formData={formData} setFormData={setFormData} />
          </div>
        );
      default:
        return null;
    }
  };

  const handleGuest = async () => {
    setStep(2); // Move to the next step after selecting guest
    setMessage('אנא המתינו לטעינת הנתונים');
  };
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const storage = getStorage();
      const storageRef = ref(storage, `conditional_approval/${file.name}`);
      setUploadedFileName(file.name); // Set the file name
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress); // Update progress state
        },
        (error) => {
          console.error('Error uploading file: ', error);
          logError(error, formData.teudatZehut, "Register.js - handleFileChange");
        },
        async () => {
          const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData((prevData) => ({
            ...prevData,
            conditionalApprovalFileUrl: fileUrl,
          }));
          setUploadProgress(0); // Reset progress after upload is complete
        }
      );
    }
  };

  const handleFileRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      conditionalApprovalFileUrl: '',
    }));
    setUploadedFileName('');
  };

  const validateTeudatZehut = (id) => {
    // Ensure the ID is exactly 9 digits long
    if (id.length !== 9 || isNaN(id)) return false;
  
    let sum = 0;
  
    // Iterate through each digit
    for (let i = 0; i < 9; i++) {
      let digit = parseInt(id[i], 10);
  
      // Alternate weights: multiply by 1 for odd positions, by 2 for even positions
      let weight = i % 2 === 0 ? 1 : 2;
      let product = digit * weight;
  
      // If product is greater than 9, add the sum of the digits (i.e., 12 -> 1 + 2 = 3)
      sum += product > 9 ? product - 9 : product;
    }
    console.log(sum,sum % 10);
    // If the sum is a multiple of 10, the ID is valid
    return sum % 10 === 0;
  }

  const handleNext = async () => {
    setLoading(true);
    setMessage('אנא המתינו לטעינת הנתונים');


    if (
      formData.teudatZehut.length !== 9 || // Checks ID length
      isNaN(formData.teudatZehut) ||       // Checks if ID contains only numbers
      !validateTeudatZehut(formData.teudatZehut) || // Checks if ID passes the validation
      formData.teudatZehut === '000000000'
  ) {
      alert('תעודת זהות חייבת להיות בעלת 9 ספרות ואו מספר תיקני');
      setLoading(false);
      return;
  }
  

    if (formData.registrationType === '' && step === 2) {
      alert('יש לבחור סוג אימון');
      setLoading(false)
      return;
    }

    if (step === 2 && formData.teudatZehut) {
      try {
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where("teudatZehut", "==", formData.teudatZehut));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0]; // Assuming teudatZehut is unique
          const userData = userDoc.data();
  
          setFormData((prevData) => ({
            ...prevData,
            ...userData,
            registrationType: prevData.registrationType // Preserve current registrationType
        }));
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        logError(error,formData.teudatZehut,"Register.js - handleNext");
      }
    }
    if (
      step === 3 && (
          formData.fullName.length < 2 ||                   // Check full name length
          formData.phoneNumber.length !== 10 ||             // Check if phone number has exactly 10 digits
          isNaN(formData.phoneNumber) ||                    // Ensure phone number is numeric
          !formData.phoneNumber.startsWith('05')            // Check if phone number starts with "05"
      )
    ) {
        alert('יש למלא שם ומספר טלפון תקין שמתחיל ב-05 ובאורך 10 ספרות');
        setLoading(false);
        return;
    }
  
    if(step === 3 && formData.gunLicenseId.length < 2){
      alert('יש להכניס מספר רישיון נשק')
      setLoading(false);
      return;
    }
    if(step === 3 && formData.licenseValidity.length < 3 && formData.registrationType !== 'הכשרה ראשונית'){
      alert('יש להכניס תוקף רישיון - מופיע ברישיון (גם ברישיון הזמני)')
      setLoading(false);
      return;
    }
    if(step === 3 && (formData.pistolManufacturer === "none" || formData.pistolManufacturer === "") && formData.registrationType !== 'הכשרה ראשונית'){
      alert('יש לבחור יצרן וקליבר כדי להמשיך')
      setLoading(false);
      return;
    }

    if(step === 3 && formData.imprintOnPistol.length < 2 && formData.registrationType !== 'הכשרה ראשונית'){
      alert('המופיע יש להכניס את המספר כלי של האקדח המופיע ברישיון')
      setLoading(false);
      return;
    }

    if(step === 3 && formData.registrationType === 'הכשרה ראשונית' && !formData.conditionalApprovalFileUrl){
      alert('יש להעלות את האישור המותנה על מנת להמשיך')
      setLoading(false);
      return;
    }
    

  
    setStep((prevStep) => prevStep + 1);
    setLoading(false);

    // Use scrollIntoView and then scrollTo the absolute top
    
    document.documentElement.scrollTop = 0; // For most browsers
    document.body.scrollTop = 0; // For Safari
  };
  

  const handleReturn = async() => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('נא לא לסגור את החלון עד לקבלת הודעת הסיום - מנפיק מסמכים עבורך');

    if (formData.isLoading) return;

    if (!validateForm()) {
        setLoading(false);
        return;
    }

    try {
        const signatureData = sigCanvas.current.toDataURL('image/png');
        const rangeId = await generateRangeId();

        // Prepare the form data without the signature
        const updatedFormData = prepareFormData(signatureData,rangeId);

        // Save the user data first
        await saveUserData(updatedFormData);

        // Save the signature separately

        setModalTitle('נרשמת בהצלחה');  // Set modal title for success
        setModalMessage(` היי ${formData.fullName} נרשמת ל${formData.registrationType} בהצלחה`);  // Set modal message for success
        setModalOpen(true);  // Open the modal

    } catch (error) {
        handleError(error);

        setModalTitle('ההרשמה נכשלה');  // Set modal title for failure
        setModalMessage(`אנא פנה למדריך עם הודעה זאת - שגיאת ${error}`);  // Set modal message for failure
        setModalOpen(true);  // Open the modal
    } finally {
        setLoading(false);
        setFormData((prevData) => ({ ...prevData, isLoading: false }));
    }
  };

const validateForm = () => {
    if (sigCanvas.current.isEmpty()) {
        alert(text[language].signatureRequired);
        return false;
    }

    if (!formData.healthDeclarationConfirm && !formData.healthUpdateConfirm) {
        alert(text[language].HealthDeclarationRequired);
        return false;
    }

    return true;
};

const prepareFormData = (signatureData,rangeId) => {
    return {
        ...formData,
        rangeId,
        registrationDate: new Date().toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' }),
        signatureUrl: signatureData,
        pistolManufacturer: formData.pistolManufacturer === 'Other' ? formData.otherOption : formData.pistolManufacturer,
        homeNumber: formData.homeNumber || '',
        zip: formData.zip || '',
        street: formData.street || '',
        city: formData.city || '',
        phoneNumber: formData.phoneNumber || '',
        email: formData.email || '',
        status: 'ממתין לאישור',
    };
};

const saveUserData = async (data) => {
  // Save registration data to the registrations collection
  const dateKey = await addRegistration(data.rangeId, data);

  // If the user has opted into the newsletter, save them as a subscriber
  if (data.newsletter) {
    await addSubscriber({
      email: data.email,
      name: data.fullName,
      mobile: data.phoneNumber,
    });
  }

  // Save user data to users collection and remove signatureUrl from registrations if successful
  if (user) {
    const userDocRef = doc(db, 'users', user.uid);

    try {
      // Save data to the users collection
      await setDoc(userDocRef, data, { merge: true });
      console.log('User data successfully saved in the users collection.');

      // Remove signatureUrl from the registration document
      const registrationDocRef = doc(db, 'registrations', data.rangeId);
      await setDoc(registrationDocRef, { signatureUrl: {} }, { merge: true });
      console.log('Signature URL successfully removed from the registration document.');

    } catch (error) {
      console.error('Error saving user data or removing signature URL:', error);
      logError(error, data.teudatZehut, "saveUserData");
      throw new Error('Failed to save user data or remove signature URL');
    }

  } else {
    await addNonLoggedMember(data,dateKey);
  }
};




const handleError = (error) => {
    console.error('Error during registration:', error);
    logError(error, formData.teudatZehut, "Register.js - handleSubmit");
    alert('Registration failed. Please try again.');
};


  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="pre-form-options" >
            {user ? handleNext() : null}
            <h2>טופס הרשמה לאימון</h2>
            <p>{text[language].formLanguageNotice}</p>
            
            <button className="pre-form-button" onClick={handleGuest}>
              להתחלת הרשמה
            </button>
          </div>
        );
      case 2:
        return (
          <div>
            <label>
              {text[language].registrationType}
              <select className='register-select' name="registrationType" value={formData.registrationType} onChange={handleChange} required>
                <option value="">{text[language].selectOption}</option>
                <option value="חידוש רישיון">חידוש רישיון</option>
                <option value="רענון תקופתי">רענון תקופתי</option>
                <option value="ירי חופשי">ירי השתפרות - "חופשי"</option>
                <option value="הכשרה ראשונית">הכשרה ראשונית</option>
                <option value="הכשרה ארגונית">הכשרה ארגונית</option>
                <option value="בדיקת תקינות נשק">בדיקת תקינות נשק</option>
              </select>
            </label>

            <label>
              {text[language].teudatZehut}
              <input className='register-input' type="number" name="teudatZehut" value={formData.teudatZehut} onChange={handleChange} required />
            </label>

            <button className='form-button' onClick={handleNext}>{text[language].next}</button>
          </div>
        );
      case 3:
        return (
          <div >
            <label>
              {text[language].fullName}
              <input className='register-input' type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
            </label>

            <label>
              {text[language].phoneNumber}
              <input className='register-input' type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
            </label>

            <label >
              דוא"ל (אופציונלי)
              <input className='register-input' type="email" name="email" value={formData.email} onChange={handleChange} />
            </label>
              <label>
            {formData.registrationType !== 'הכשרה ראשונית' && (
              <div className='holsterWarning'>
              <img className='warning_icon' src={warning_icon} alt='warning icon' />
              <p>בשום פנים אין להוציא את האקדח מהנרתיק</p>
              <img className='warning_icon' src={warning_icon} alt='warning icon' />
            </div>
            )}
            <p>את הנתונים הבאים ניתן להשיג מהרשיון או מהאישור המותנה</p>
              {formData.registrationType === 'הכשרה ראשונית' && (
                <div className='uploaded-file'>
                  <label htmlFor="file">העלאת אישור מותנה - יש להעלות את הקובץ המקורי שנשלח אליכם במייל, במידה ואין לכם אותו יש לצלם ולהקפיד על איכות ברורה וקריאה</label>
                  
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".pdf,.jpg,.jpeg,.png"
                    className="file-input"
                  />
                {uploadProgress > 0 && (
                  <div className="upload-bar">
                    <div className="upload" style={{ width: `${uploadProgress}%` }}>
                      {uploadProgress.toFixed(2)}%
                    </div>
                  </div>
                )}
                {formData.conditionalApprovalFileUrl && (
                  <div className="details-row">
                    <p className='uploaded-file'>הקובץ הועלה בהצלחה: <a href={formData.conditionalApprovalFileUrl} target="_blank" rel="noopener noreferrer">{uploadedFileName}</a></p>
                    <button className="remove-file-button" onClick={handleFileRemove}>הסר קובץ</button>
                  </div>
                )}
                </div>
              )}
                {formData.registrationType === 'הכשרה ראשונית' ? text[language].gunLicenseId + ' - מופיע באישור המותנה':text[language].gunLicenseId}
                <input className='register-input' type="number" name="gunLicenseId" value={formData.gunLicenseId} onChange={handleChange} required />
              </label>
            {formData.registrationType !== 'הכשרה ראשונית' && (
              <div>
              <label>
                תוקף רישיון
                <input className='register-input' type="date" name="licenseValidity" placeholder='dd/mm/yyyy'
                value={formData.licenseValidity} onChange={handleChange} disabled={formData.registrationType === 'firstTraining'} required/>
              </label>

              <label>
                {text[language].imprintOnPistol+ ' - רשום ברישיון!'}
                <input className='register-input' type="text" name="imprintOnPistol" value={formData.imprintOnPistol } onChange={handleChange} disabled={formData.registrationType === 'firstTraining'} />
              </label>
              <label>
              יצרן האקדח
              <select className='register-select' name="pistolManufacturer" value={formData.pistolManufacturer} onChange={handleChange} required>
                <option value="none">{text[language].selectOption}</option>
                <option value="Glock">Glock - גלוק</option>
                <option value="Sig Sauer">Sig Sauer - סיג סאוור</option>
                <option value="IWI">IWI</option>
                <option value="Smith & Wesson">Smith & Wesson - סמית' אנד ווסון</option>
                <option value="CZ">CZ - סי זד</option>
                <option value="Beretta">Beretta - ברטה</option>
                <option value="Karin">Karin - קארין</option>
                <option value="Walther">Walther - וולטר</option>
                <option value="BUL">BUL - בול</option>
                <option value="FN">FN - בראונינג</option>
                <option value="Ruger">Ruger - רוגר</option>
                <option value="Springfield">Springfield - ספרינגפילד</option>
                <option value="Shadow System">Shadow Systems - שאדו סיסטמס</option>
                <option value="Colt">Colt - קולט</option>
                <option value="Mossberg">Mossberg - מוסברג</option>
                <option value="HELLCAT">HELLCAT - הלקט</option>
                <option value="Taurus">Taurus - טאורוס</option>
                <option value="Ramon">Ramon - רמון</option>
                <option value="Other">אחר</option>
              </select>
            </label>
          {/* Conditionally render the input field if "Other" is selected */}
          {formData.pistolManufacturer === 'Other' && (
            <label>
              <input
                className='register-input'
                type="text"
                name="otherOption"
                value={formData.otherOption}
                onChange={handleChange}
                placeholder="Specify other manufacturer"
                required
              />
            </label>
          )}
          <label>
            קוטר קליע/קליבר
            <select className='register-select' name="ammunitionCaliber" value={formData.ammunitionCaliber} onChange={handleChange} required>
              <option value="none"> בחר</option>
              <option value='9x19'>9x19</option>
              <option value='9x17'>9x17</option>
              <option value='7.65browning'>7.65 Browning</option>
              <option value='22LR'>22LR</option>
              <option value='22WM'>22WM</option>
              <option value='45AUTO'>45AUTO</option>
              <option value='44MAG'>44MAG</option>
              <option value='20S&W'>20S&W</option>
              <option value='22SHORT'>22SHORT</option>
              <option value='6.35'>6.35</option>
              <option value='38SPL'>38SPL</option>
              <option value='222REM'>222REM</option>
              <option value='22HORNET'>22HORNET</option>
              <option value='50AE'>50AE</option>
              <option value='32S&WLONG'>32S&WLONG</option>
              <option value='357MAG'>357MAG</option>
              <option value='38S&W'>38S&W</option>
              <option value='41AE'>41AE</option>
              <option value='7.63x25'>7.63x25</option>
              <option value='12Gauge'>12Gauge</option>
              <option value='5.56x45'>5.56x45</option>
              <option value='Other'>אחר - יש לעדכן את המדריך</option>
            </select>
          </label>
          
          </div>)}


            <div className='nav-buttons'>
              <button className='form-button' onClick={handleReturn}>{text[language].prev}</button>
              <button className='form-button' onClick={handleNext}>{text[language].next}</button>
              </div>
          </div>
        );
      case 4:
        return (
          <form onSubmit={handleSubmit}>
            <div className='sub-form-frame' >
              <h2>יש לקרוא את הקטעים הבאים ולסמן את הסעיפים הנכונים</h2>
              {renderSubForm()}
              <p>{text[language].signatureRequest}:

              </p>
            </div>
            <div className='signature-pad'>
                  <SignatureCanvas 
                      penColor="#3c6eb4" 
                      willReadFrequently={true}
                      canvasProps={{ className: 'sigCanvas'}} 
                      ref={sigCanvas} 
                      />
            </div>
            <button className="clear-button" onClick={() => sigCanvas.current.clear()}>נקה חתימה</button>
                
            <label className="checkbox-row">
              <p style={{width:'80%'}}>אני מצהיר בזאת כי כל המידע שמסרתי בטופס זה, מלא ומהימן</p>
              <input
              type="checkbox"
              name="healthDeclarationConfirm"
              style={{width:'20%'}}
              required
              />
            </label>

            <div className='nav-buttons'>
              <button className='form-button' onClick={handleReturn}>{text[language].prev}</button>
              <button className='form-button' type="submit">{text[language].registerButton}</button>
            </div>
          </form>
        );

      case 5:
        return (
          <div>
            <h2>{text[language].registrationSuccess}</h2>
            <p>{text[language].registrationSuccessMessage}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="register-container"  >
      <Helmet>
        <title>מטווח שי לוי - הרשמה לאימון</title>
        <meta name="description" content="הרשמה לאימון במטווח שי לוי - היכנס להרשמה מהירה" />
        <meta name="keywords" content="בדיקת תיקנות נשק, רענון תקופתי, מטווח , מטווח שי לוי , הכשרה ראשונית , רישיון לאקדח, גלוק, Glock, מאבטחים, חידוש רישיון, קרני שומרון" />
      </Helmet>
      <ProgressBar step={step-1} />
      {loading ? (<div>
        <div className="spinner"></div>
        <p style={{textAlign:'center'}}>{message}</p>
      </div>) : (
        <>
        {renderStepContent()}
        </>
      )}
      <Modal 
        isOpen={modalOpen} 
        onClose={() => setModalOpen(false)} 
        onConfirm={handleModalConfirm}  // Add onConfirm to handle OK button
        title={modalTitle} 
        message={modalMessage} 
      />
    </div>
  );
};

export default Register;
