import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import reloadIcon from '../icons/icons8-reload-100.png';
import '../styles/ClientList.css';
import ClientManagementPopup from './ClientManagementPopup';

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('today');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [sortOption, setSortOption] = useState('הכל');
  const [filterStatus, setFilterStatus] = useState('');
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [totalRegistrations, setTotalRegistrations] = useState(0);
  const [pendingApprovalCount, setPendingApprovalCount] = useState(0);

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const registersCollectionRef = collection(db, 'registrations');
        const snapshot = await getDocs(registersCollectionRef);
        let datesData = new Set();
        let today = new Date();
        const todayKey = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
        
        if (!snapshot.empty) {
          snapshot.docs.forEach(doc => {
            // Remove suffix to display a single date entry
            const baseDate = doc.id.split('-').slice(0, 3).join('-');
            datesData.add(baseDate);
          });
        }

        setDates(['today', ...Array.from(datesData).sort()]);
        setSelectedDate('today');
      } catch (error) {
        console.error('Error fetching dates:', error);
      }
    };
    fetchDates();
  }, []);

  const fetchClients = async () => {
    if (!selectedDate) return;
    setLoading(true);

    try {
      let clientsData = [];

      if (selectedDate === 'today') {
        const today = new Date();
        const todayKey = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
        const dateSuffixes = ['', '-08', '-12'];

        for (const suffix of dateSuffixes) {
          const dateDocRef = doc(db, 'registrations', todayKey + suffix);
          const dateDocSnap = await getDoc(dateDocRef);
          if (dateDocSnap.exists()) {
            clientsData.push(...Object.entries(dateDocSnap.data()).map(([id, data]) => ({ id, ...data })));
          }
        }
      } else {
        const dateSuffixes = ['', '-08', '-12'];
        for (const suffix of dateSuffixes) {
          const dateDocRef = doc(db, 'registrations', selectedDate + suffix);
          const dateDocSnap = await getDoc(dateDocRef);
          if (dateDocSnap.exists()) {
            clientsData.push(...Object.entries(dateDocSnap.data()).map(([id, data]) => ({ id, ...data })));
          }
        }
      }

      const filteredClients = clientsData.filter(client => {
        return (
          (filterStatus ? client.status === filterStatus : true) &&
          (sortOption === 'הכל' ? true : client.registrationType === sortOption)
        );
      });

      filteredClients.sort((a, b) => new Date(a.registrationDate) - new Date(b.registrationDate));
      setClients(filteredClients);

      // Update registration counts
      setTotalRegistrations(clientsData.length);
      setPendingApprovalCount(clientsData.filter(client => client.status !== 'ממתין לאישור').length);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchClients();
  }, [selectedDate, sortOption, filterStatus]);

  const handleReload = () => {
    fetchClients();
  };

  const handleManageClient = (client) => {
    setSelectedClient(client);
  };

  const closePopup = () => {
    setSelectedClient(null);
  };

  const updateClient = (updatedClient) => {
    setClients(clients.map(client => client.id === updatedClient.id ? updatedClient : client));
  };

  const deleteClient = (clientId) => {
    setClients(clients.filter(client => client.id !== clientId));
  };

  const toggleGroup = (type) => {
    setCollapsedGroups(prev => ({ ...prev, [type]: !prev[type] }));
  };

  const groupedClients = clients.reduce((acc, client) => {
    const { registrationType } = client;
    if (!acc[registrationType]) acc[registrationType] = [];
    acc[registrationType].push(client);
    return acc;
  }, {});

  const filteredClients = clients.filter(client => 
    client.teudatZehut?.includes(searchQuery) ||
    client.phoneNumber?.includes(searchQuery)
  );

  return (
    <div className="client-list-container">
      <h2>נרשמים</h2>

      <div className="controls">
        <select 
          value={selectedDate} 
          onChange={(e) => setSelectedDate(e.target.value)}
          className="date-dropdown"
        >
          <option value="today">היום</option>
          {dates.map(date => (
            <option key={date} value={date}>{date}</option>
          ))}
        </select>

        <input 
          type="text" 
          placeholder="חיפוש לפי ת.ז או מספר נייד" 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
          className="search-bar"
        />

        <button onClick={handleReload} className="reload-button">
          <img src={reloadIcon} alt="Reload" className="reload-icon" />
        </button>

        <div className="registration-summary">
          <p>סה"כ נרשמים : {totalRegistrations}</p>
          <p>סה"כ מאומתים : {pendingApprovalCount}</p>
          <p>נותר לטיפול : {totalRegistrations - pendingApprovalCount}</p>
        </div>
      </div>

      {searchQuery && (
        <div className="search-results">
          <h3>תוצאות חיפוש עבור "{searchQuery}"</h3>
          {filteredClients.length > 0 ? (
            <ul>
              {filteredClients.map(client => (
                <li className='user-query' key={client.id} onClick={() => handleManageClient(client)}>
                  {client.fullName} - ת.ז: {client.teudatZehut} - נייד: {client.phoneNumber}
                </li>
              ))}
            </ul>
          ) : (
            <p>לא נמצאו תוצאות</p>
          )}
        </div>
      )}

      {loading ? (
        <p>טוען נרשמים...</p>
      ) : (
        Object.keys(groupedClients).map(type => (
          <div key={type} className="registration-group">
            <div className="group-header" onClick={() => toggleGroup(type)}>
              {type} {collapsedGroups[type] ? '▲' : '▼'}
            </div>
            {!collapsedGroups[type] && (
              <table className="client-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>שם</th>
                    <th>ת"ז</th>
                    <th>מספר נייד</th>
                    <th>סטטוס</th>
                    <th>תאריך</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedClients[type].map((client, index) => (
                    <tr key={client.id} onClick={() => handleManageClient(client)}>
                      <td>{client.rangeId}</td>
                      <td>{client.fullName}</td>
                      <td>{client.teudatZehut}</td>
                      <td>{client.phoneNumber}</td>
                      <td>{client.status || 'ממתין לאישור'}</td>
                      <td>{client.registrationDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        ))
      )}

      {selectedClient && (
        <ClientManagementPopup 
          client={selectedClient} 
          onClose={closePopup} 
          onUpdateClient={updateClient} 
          onDeleteClient={deleteClient} 
        />
      )}
    </div>
  );
};

export default ClientList;
