import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import HealthDeclaration from '../pdfs/wall_safe_commitment-compressed.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl, db } from '../components/firebase'; // Import the upload function and db
import { doc, getDoc } from "firebase/firestore";

const generateSafeCommit = async (formData) => {
  const existingPdfUrl = HealthDeclaration;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  // Load a font that supports Hebrew characters
  const fontBytes = await fetch(open_sans_font).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const fullName = formData.fullName || 'שם משתמש';
  const teudatZehut = formData.teudatZehut || '123456789';
  const currentDate = new Date().toLocaleDateString('he-IL');

  // Fetch the signatureUrl from the 'users' collection using teudatZehut as the key
  let signatureUrl = null;
  try {
    const userDocRef = doc(db, 'users', teudatZehut);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      signatureUrl = userDoc.data().signatureUrl;
    } else {
      console.warn(`No user found with Teudat Zehut: ${teudatZehut}`);
    }
  } catch (error) {
    console.error('Error fetching user data from Firestore:', error);
  }

  // Position the fields on the PDF (adjust positions as needed)
  firstPage.drawText(fullName, { x: 330, y: 630, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 190, y: 630, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(currentDate, { x: 450, y: 390, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });

  // Add the signature if available
  if (signatureUrl) {
    const signatureImage = await pdfDoc.embedPng(signatureUrl);
    firstPage.drawImage(signatureImage, { x: 230, y: 380, width: 150 * 1.1, height: 50 * 1.1 });
  }

  const pdfBytes = await pdfDoc.save();

  // Create a blob from the PDF bytes
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `safe_commitment_${teudatZehut}_${fullName}_${currentDate}.pdf`;
  downloadLink.click();

  // Optionally, you can upload the PDF and return the download URL
  // const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'safe_commitments', `safe_commitment_${teudatZehut}_${fullName}_${currentDate}`);
  // return downloadUrl;
};

export default generateSafeCommit;
