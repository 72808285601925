import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import TransactionPDF from '../pdfs/transaction_agreement.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl } from '../components/firebase'; // Import the upload function

// Helper function to reverse numbers in a string
const reverseNumbersInString = (input) => {
  if (typeof input !== 'string') return input; // Return as-is if the input is not a string

  // Check if the input is only text (no numbers)
  if (/^[^\d]*$/.test(input)) {
    return input; // Return as-is if there are no numbers
  }

  // Check if the input is only numbers
  if (/^\d+$/.test(input)) {
    return input; // Return as-is if it's only numbers
  }

  // If the input contains both text and numbers, reverse the numbers
  return input.replace(/\d+/g, (match) => {
    return match.split('').reverse().join('');
  });
};

const generateTransaction = async (formData) => {
  try {
    // Load the existing PDF template
    const existingPdfUrl = TransactionPDF;
    const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Register fontkit to enable custom font embedding
    pdfDoc.registerFontkit(fontkit);

    // Load a font that supports Hebrew characters
    const fontBytes = await fetch(open_sans_font).then(res => res.arrayBuffer());
    const hebrewFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Extract form data fields with default values
    const fullName = formData.fullName || 'שם משתמש';
    const teudatZehut = formData.teudatZehut || '123456789';
    const number = formData.phoneNumber || '0501234567';
    const address = formData.city && formData.street ? `${formData.city} ${formData.street}` : 'כתובת';
    const licenseNumber = formData.gunLicenseId || '123456';
    const rangeId = formData.rangeId ? `${formData.rangeId}_${formData.registrationDate.split(',')[0].split('.')[2]}` : '';
    let remainingAmount = formData.remainingAmount || 0;
    const advancePayment = formData.advancePayment || 0;
    const buyerSignature = formData.buyerSignature || null;
    const sellerSignature = formData.sellerSignature || null;
    const transactionData = Array.isArray(formData.transactionData) ? formData.transactionData : [];
    const currentDate = formData.registrationDate || '';

    // Debugging: Check the values to ensure they are correct
    console.log('Advance Payment:', advancePayment);
    console.log('Remaining Amount:', remainingAmount);
    console.log('Transaction Data:', transactionData);

    // Position the fields on the PDF
    firstPage.drawText(fullName, { x: 400, y: 675, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(teudatZehut, { x: 150, y: 675, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(number, { x: 400, y: 650, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(address, { x: 150, y: 650, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(licenseNumber, { x: 400, y: 625, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(rangeId, { x: 150, y: 625, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });

    // Add transaction details
    let yPosition = 560; // Start position for the transaction details
    transactionData.forEach((item) => {
      if (!item.price) return; // Skip items with no price

      const price = Number(item.price) || 0;
      const discount = item.discount ? parseFloat(item.discount) / 100 : 0;
      const discountedPrice = Math.floor(price * (1 - discount));
      
      // Draw the item details on the PDF
      firstPage.drawText(item.itemName || '', { x: 450, y: yPosition, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
      firstPage.drawText(reverseNumbersInString(item.description.toString() || ''), { x: 300, y: yPosition, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
      firstPage.drawText(price ? `${price} ₪` : '', { x: 200, y: yPosition, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
      
      // Display discount and final discounted price
      firstPage.drawText(item.discount ? `(${item.discount})` : '', { x: 165, y: yPosition, size: 9, font: hebrewFont, color: rgb(0.5, 0.5, 0.5) });
      firstPage.drawText(discountedPrice ? `${discountedPrice} ₪` : '', { x: 125, y: yPosition, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });

      if (item.supplied === true) {
        firstPage.drawText('סופק ', { x: 75, y: yPosition, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });
      }

      yPosition -= 18; // Move to the next line
    });

    // Add payment details
    const totalAmount = transactionData.reduce((total, item) => {
      const price = Number(item.price) || 0;
      const discount = item.discount ? parseFloat(item.discount) / 100 : 0;
      const discountedPrice = Math.floor(price * (1 - discount));
      return total + discountedPrice;
    }, 0);
    remainingAmount = Math.floor(totalAmount - advancePayment);

    firstPage.drawText(`מקדמה ששולמה :`, { x: 400, y: 350, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(`${advancePayment.toFixed(2)}₪`, { x: 330, y: 350, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(`יתרה לתשלום :`, { x: 400, y: 330, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(`${remainingAmount.toFixed(2)}₪`, { x: 330, y: 330, size: 12, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(`${(remainingAmount + advancePayment).toFixed(2)} ₪`, { x: 400, y: 230, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });
    
    firstPage.drawText(currentDate, { x: 400, y: 200, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });

    // Add the signatures
    if (sellerSignature) {
      const signatureImage = await pdfDoc.embedPng(sellerSignature);
      firstPage.drawImage(signatureImage, { x: 90, y: 222, width: 150 * 0.6, height: 50 * 0.6 });
    }
    if (buyerSignature) {
      const signatureImage = await pdfDoc.embedPng(buyerSignature);
      firstPage.drawImage(signatureImage, { x: 90, y: 190, width: 150 * 0.6, height: 50 * 0.6 });
    }

    // Save the modified PDF and upload it
    const pdfBytes = await pdfDoc.save();
    const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'transactions', `transaction_${teudatZehut}_${fullName}_${currentDate}`);

    return downloadUrl;
  } catch (error) {
    console.error('Error generating transaction PDF:', error);
    throw new Error('Failed to generate transaction PDF.');
  }
};

export default generateTransaction;

