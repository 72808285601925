import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/ShooterLog.css';
import TransactionList from './TransactionList';
import { CSVLink } from 'react-csv';
import ShooterLogForm from './ShooterLogForm';
import ClientManagementPopup from './ClientManagementPopup'; // Import the popup
import { generateRangeId } from './firebase';


const ShooterLog = () => {
  const [ledgerEntries, setLedgerEntries] = useState([]);
  const [selectedDate, setSelectedDate] = useState('all');
  const [dates, setDates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentEditingEntry, setCurrentEditingEntry] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null); // Track the selected client for popup
  const [batchSize, setBatchSize] = useState(200); // Batch size of 200 entries
  const [currentBatch, setCurrentBatch] = useState(1);
  const [hasMoreEntries, setHasMoreEntries] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const registrationDocs = await getDocs(collection(db, 'registrations'));
        const uniqueDates = registrationDocs.docs
          .map(doc => doc.id)
          .filter(date => date.startsWith(`${selectedYear}`))
          .sort()
          .reverse();
        setDates(uniqueDates);
      } catch (error) {
        console.error('Error fetching dates:', error);
      }
    };

    fetchDates();
  }, [selectedYear]);

  useEffect(() => {
    fetchLedgerEntries();
  }, [selectedDate, selectedYear]);

  const fetchLedgerEntries = async (isLoadMore = false) => {
    try {
      const allEntries = [];
      const dateVariants = 
        selectedDate === 'all' 
          ? await getDocs(collection(db, 'registrations'))
          : [
              selectedDate,
              `${selectedDate}-08`,
              `${selectedDate}-12`
            ].map(dateVariant => getDoc(doc(collection(db, 'registrations'), dateVariant)));
  
      const registrationDocs = selectedDate === 'all' ? dateVariants.docs : await Promise.all(dateVariants);
      
      registrationDocs.forEach(doc => {
        if (doc.exists()) {
          const data = doc.data();
          const entries = Object.entries(data)
            .map(([id, entryData]) => ({
              ...entryData,
              rangeId: parseInt(id, 10),
              dateKey: doc.id,
            }))
            .filter(entry => entry.dateKey && entry.dateKey.startsWith(`${selectedYear}`));
          allEntries.push(...entries);
        }
      });
  
      const sortedEntries = allEntries.sort((a, b) => b.rangeId - a.rangeId);
      const paginatedEntries = sortedEntries.slice(0, currentBatch * batchSize);
  
      if (isLoadMore) {
        setLedgerEntries(prevEntries => [...prevEntries, ...paginatedEntries.slice(prevEntries.length)]);
      } else {
        setLedgerEntries(paginatedEntries);
      }
  
      setHasMoreEntries(paginatedEntries.length < sortedEntries.length);
    } catch (error) {
      console.error('Error fetching ledger entries:', error);
    }
  };

  const handleLoadMore = async () => {
    setIsLoading(true);
    setCurrentBatch(prevBatch => prevBatch + 1);

    await fetchLedgerEntries(true); // Wait for entries to load
    setIsLoading(false);
  };
  
  
  // Modify the useEffect to reset batch on date or year change
  useEffect(() => {
    setCurrentBatch(1); // Reset batch to 1 on date/year change
    fetchLedgerEntries();
  }, [selectedDate, selectedYear]);

  const handleSearch = async () => {
    try {
      if (!selectedDate && selectedDate !== 'all') {
        alert('Please select a date first.');
        return;
      }

      const allEntries = [];
      const dateVariants = selectedDate === 'all' ? [] : [
        selectedDate,
        `${selectedDate}-08`,
        `${selectedDate}-12`,
      ];

      const registrationDocs =
        selectedDate === 'all'
          ? await getDocs(collection(db, 'registrations'))
          : await Promise.all(dateVariants.map(variant => getDoc(doc(collection(db, 'registrations'), variant))));

      registrationDocs.forEach(doc => {
        if (doc.exists()) {
          const data = doc.data();
          const filteredEntries = Object.entries(data)
            .filter(
              ([, entry]) =>
                entry.phoneNumber === searchQuery || entry.teudatZehut === searchQuery
            )
            .map(([id, entryData]) => ({
              ...entryData,
              rangeId: parseInt(id, 10),
              dateKey: doc.id,
            }));
          allEntries.push(...filteredEntries);
        }
      });

      setLedgerEntries(allEntries.sort((a, b) => b.rangeId - a.rangeId));
    } catch (error) {
      console.error('Error searching ledger:', error);
    }
  };
    
  const handleRangeIdClick = (entry) => {
    setSelectedClient(entry); // Set the selected client for the popup
  };

  const handleTeudatZehutClick = teudatZehut => {
    setSelectedUser(teudatZehut);
  };

  const handleAddNew = () => {
    setCurrentEditingEntry(null);
    setIsFormOpen(true);
  };

  const handleSave = async (formData,dateKey) => {
    try {
      // Generate rangeId and dateKey if not provided
      const rangeId = await generateRangeId();
      console.log(dateKey);
  
      // Reference to the registration document
      const docRef = doc(db, 'registrations', dateKey);
      const docSnap = await getDoc(docRef);
  
      // Check if rangeId already exists in the document
      if (docSnap.exists() && docSnap.data()[rangeId]) {
        alert(`Range ID ${rangeId} already exists. Please use a unique ID.`);
        return;
      }
  
      // Add or update entry in Firestore with the new rangeId and dateKey
      await setDoc(
        docRef,
        { [rangeId]: { ...formData, rangeId, dateKey } },
        { merge: true }
      );
  
      // Refresh the entries after saving
      fetchLedgerEntries();
    } catch (error) {
      console.error('Error saving entry:', error);
    }
  };

  const downloadCSV = entries => {
    return entries.map(entry => ({
      'מספר': entry.rangeId,
      'כניסה לאימון': entry.registrationDate,
      'שם ומשפחה': entry.fullName,
      'תז': entry.teudatZehut,
      'סיבת הירי': entry.registrationType,
      'הצהרת בריאות': entry.healthDeclarationConfirm ? '✔' : '',
      'תוקף רישיון': entry.licenseValidity,
      'סוג כלי היריה': entry.registrationType === 'הכשרה ראשונית' ? 'גלוק' : entry.pistolManufacturer,
      'בעלות': entry.registrationType === 'הכשרה ראשונית' ? 'מטווח' : 'פרטי',
      'מס כלי': entry.imprintOnPistol,
      'קליבר': entry.registrationType === 'הכשרה ראשונית' ? '9x19' : entry.ammunitionCaliber,
      'כמות': entry.bulletCount,
      'שעת סיום האימון': entry.trainingEndTime,
      'חתימת המאמן': entry.verifiedBy,
      'חתימת המדריך': entry.managedBy,
    }));
  };

  const groupDatesByMonth = dates => {
    const grouped = {};

    dates.forEach(date => {
      if (date) { // Ensure date is defined
        const cleanDate = date.split('-')[0]; // Strip any suffixes like '-08' or '-12'
        const [year, month] = cleanDate.split('-'); // Ensure date exists
        const key = `${year}-${month}`;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(date);
      }
    });

    return grouped;
  };

  const groupedDates = groupDatesByMonth(dates);

  const years = Array.from(new Set(dates.map(date => date.split('-')[0]))).sort(); // Ensure date is defined

  const closePopup = () => {
    setSelectedClient(null); // Close the popup
  };

  const updateClient = (updatedClient) => {
    setLedgerEntries(ledgerEntries.map(entry => entry.rangeId === updatedClient.rangeId ? updatedClient : entry));
  };

  return (
    <div className="table-container">
      <h2>יומן יורים</h2>
      <div className="controls">
        <div className="table-row">
          <button onClick={handleAddNew}>הוסף רישום חדש</button>
  
          <input
            type="text"
            placeholder="חפש לפי מספר נייד או תעודת זהות"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <button onClick={handleSearch}>חפש</button>
          <button
            onClick={() => {
              setSearchQuery('');
              setSelectedDate('all');
            }}
          >
            איפוס חיפוש
          </button>
        </div>
        <div className="table-row">
          <select value={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
            {years.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </select>
          <select value={selectedDate} onChange={e => setSelectedDate(e.target.value)}>
            <option value="all">כל התאריכים</option>
            {Object.keys(groupedDates).map((month, index) => (
              <optgroup key={index} label={month}>
                {groupedDates[month].map((date, idx) => (
                  <option key={idx} value={date}>
                    {date}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
  
          {selectedDate && (
            <CSVLink data={downloadCSV(ledgerEntries)} filename={`ledger-${selectedDate}.csv`}>
              <button>הורד גיליון </button>
            </CSVLink>
          )}
        </div>
      </div>
      <div className='table-overflow'>
        <table className="table-table">
          <thead>
            <tr>
              <th colSpan='5'>פרטי היורה</th>
              <th colSpan="4">סיבת הירי</th>
              <th colSpan='2'></th>
              <th colSpan="3">פרטי כלי היריה</th>
              <th colSpan="2">תחמושת שנורתה</th>
              <th colSpan="3">פרטי האימון</th>
            </tr>
            <tr>
              <th rowSpan="2">מספר</th>
              <th rowSpan="2">כניסה לאימון</th>
              <th rowSpan="2">שם ומשפחה</th>
              <th rowSpan="2">תז</th>
              <th rowSpan="2">נייד</th>
              <th>אימון ואימות לקבלת רישיון</th>
              <th>חידוש רישון </th>
              <th>אימון</th>
              <th>בדיקת כלי יריה</th>
              <th rowSpan="2">הצהרת בריאות</th>
              <th rowSpan="2">תוקף רישיון</th>
              <th>סוג</th>
              <th>בעלות</th>
              <th>מס' כלי</th>
              <th>קליבר</th>
              <th>כמות</th>
              <th rowSpan="2">שעת סיום האימון</th>
              <th rowSpan="2">חתימת המאמן</th>
              <th rowSpan="2">סטטוס</th>
            </tr>
          </thead>
          <tbody>
            {ledgerEntries.length > 0 ? (
              ledgerEntries.map((entry, index) => (
                
                <tr key={index}>
                  <td onClick={() => handleRangeIdClick(entry)} className="clickable">{entry.rangeId}</td> {/* Opens popup */}
                  <td>{entry.registrationDate}</td>
                  <td>{entry.fullName}</td>
                  <td onClick={() => handleTeudatZehutClick(entry.teudatZehut)} className="clickable">
                    {entry.teudatZehut}
                  </td>
                  <td>{entry.phoneNumber}</td>
                  <td>{entry.registrationType === 'הכשרה ראשונית' || entry.registrationType === 'הכשרה ארגונית' ? '✔ ' + entry.registrationType : ''}</td>
                  <td>{entry.registrationType === 'חידוש רישיון' ? '✔ ' + entry.registrationType : ''}</td>
                  <td>{entry.registrationType === 'ירי חופשי' || entry.registrationType === 'רענון תקופתי' ? '✔ ' + entry.registrationType : ''}</td>
                  <td>{entry.registrationType === 'בדיקת תקינות נשק' ? '✔ ' + entry.registrationType : ''}</td>
                  <td>{entry.healthDeclarationConfirm ? '✔' : ''}</td>
                  <td>{entry.licenseValidity}</td>
                  <td>{entry.registrationType === 'הכשרה ראשונית' ? 'גלוק' : entry.pistolManufacturer}</td>
                  <td>{entry.registrationType === 'הכשרה ראשונית' ? 'מטווח' : 'פרטי'}</td>
                  <td>{entry.registrationType === 'הכשרה ראשונית' ? '' : entry.imprintOnPistol}</td>
                  <td>{entry.registrationType === 'הכשרה ראשונית' ? '9x19' : entry.ammunitionCaliber}</td>
                  <td>{entry.bulletCount}</td>
                  <td>{entry.trainingEndTime ? entry.trainingEndTime : entry.registrationDate}</td>
                  <td>{entry.verifiedBy}</td>
                  <td style={{background: entry.status === 'מבוטל' ? 'rgba(255, 0, 0, 0.377)' : entry.status === 'ממתין לאישור' ? 'rgba(255, 196, 0, 0.377)' :
                      entry.status === 'מאומת'? 'rgba(0, 255, 55, 0.377)' : 'inherit'}}>{entry.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="19">טוען רשומות...</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="table-overflow">
          {/* ...existing code... */}
          {isLoading ? (
            <button className="load-more-button" >טוען עוד...</button>
          ): (
          ledgerEntries.length > 0 && hasMoreEntries && (
              <button onClick={handleLoadMore} className="load-more-button">הצג רשומות נוספות</button>
            )
          )}
        </div>
        {isFormOpen && (
          <ShooterLogForm
            isOpen={isFormOpen}
            onClose={() => setIsFormOpen(false)}
            onSave={handleSave}
            initialData={currentEditingEntry}
          />
        )}
        {selectedUser && (
          <div className="popup-overlay">
            <TransactionList teudatZehut={selectedUser} onClose={() => setSelectedUser(null)} />
          </div>
        )}
        {selectedClient && (
          <ClientManagementPopup
            client={selectedClient} // Pass selected client
            onClose={closePopup}
            onUpdateClient={updateClient}
          />
        )}
      </div>
    </div>
  );
}
export default ShooterLog;
