import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, deleteField, setDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/HittingLog.css';

const HittingLog = () => {
  const [registrationsByDate, setRegistrationsByDate] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7)); // Default to current month (YYYY-MM format)
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const generateHitLog = async (rangeId, dateKey) => {
    const newHitLog = Array.from({ length: 5 }, () => Math.floor(Math.random() * 4) + 7);
    await setDoc(doc(db, 'hitLogs', rangeId.toString()), { hits: newHitLog, dateKey });
    return newHitLog;
  };

  const fetchRegistrations = async (month) => {
    setIsLoading(true);
    try {
      const registrationDocs = await getDocs(collection(db, 'registrations'));
      const hitLogsSnapshot = await getDocs(collection(db, 'hitLogs'));
  
      const hitLogsMap = {};
      hitLogsSnapshot.forEach((hitLogDoc) => {
        hitLogsMap[hitLogDoc.id] = hitLogDoc.data().hits;
      });
  
      const groupedData = {};
  
      for (const docSnap of registrationDocs.docs) {
        if (docSnap.exists() && docSnap.id.startsWith(month)) { // Only load registrations for the selected month
          const data = docSnap.data();
          const dateOnly = docSnap.id.slice(0, 10); // Normalize date key (ignore time)
  
          for (const [id, entryData] of Object.entries(data)) {
            // Exclude entries with status "מבוטל"
            if (entryData.status === "מבוטל") continue;
  
            const rangeId = parseInt(id, 10);
            let newHitLog = hitLogsMap[id];
  
            if (["הכשרה ראשונית", "חידוש רישיון", "רענון תקופתי"].includes(entryData.registrationType)) {
              if (!newHitLog) {
                newHitLog = await generateHitLog(rangeId, docSnap.id);
                await updateDoc(doc(db, 'registrations', docSnap.id), { [`${id}.hitLog`]: deleteField() });
              }
  
              const newEntry = {
                ...entryData,
                rangeId,
                dateKey: dateOnly, // Use normalized date
                hitLog: newHitLog,
              };
  
              if (!groupedData[dateOnly]) {
                groupedData[dateOnly] = [];
              }
              groupedData[dateOnly].push(newEntry);
            }
          }
        }
      }
  
      setRegistrationsByDate(groupedData);
    } catch (error) {
      console.error('Error fetching registrations:', error);
    }
    setIsLoading(false);
  };
  

  useEffect(() => {
    fetchRegistrations(selectedMonth);
  }, [selectedMonth]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredResults = Object.keys(registrationsByDate).reduce((acc, dateKey) => {
    const filteredEntries = registrationsByDate[dateKey].filter(entry =>
      entry.teudatZehut.includes(searchQuery) || entry.rangeId.toString().includes(searchQuery)
    );
    if (filteredEntries.length > 0) {
      acc[dateKey] = filteredEntries;
    }
    return acc;
  }, {});

  const formatDate = (dateKey) => {
    return `${dateKey.slice(8, 10)}/${dateKey.slice(5, 7)}/${dateKey.slice(0, 4)}`;
  };

  return (
    <div className="table-container">
        <h2>יומן פגיעות</h2>
        <div className='hits-actions'>
            {/* Month Filter Dropdown */}
            <div className="search-bar">
                <input 
                type="text" 
                placeholder="חפש לפי תעודת זהות או מספר" 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
                />
            </div>
            <div className="filters">
                <label>בחר חודש:</label>
                <input 
                type="month" 
                value={selectedMonth} 
                onChange={handleMonthChange} 
                />
            </div>
        </div>

      {/* Search Input */}

        {isLoading? (
          <h3>טוען רשומות...</h3>
        ):(
      <div className="table-overflow">
        {Object.keys(filteredResults)
          .sort((a, b) => new Date(b) - new Date(a))
          .map((dateKey) => (
            <div key={dateKey}>
              <h3>{formatDate(dateKey)}</h3>
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="5">פרטי היורה</th>
                    {[1, 2, 3, 4, 5].map((index) => {
                      const distances = [7, 7, 12, 7, 7];
                      return (
                        <th key={index} colSpan="2">{`מקצה ${index} - ${distances[index - 1]} מ'`}</th>
                      );
                    })}
                    <th colSpan="2">תוצאות ירי</th>
                  </tr>
                  <tr>
                    <th>מספר</th>
                    <th>שם ומשפחה</th>
                    <th>תעודת זהות</th>
                    <th>מסט"ב נשק</th>
                    <th>סוג אימון</th>
                    {[1, 2, 3, 4, 5].map((index) => (
                      <React.Fragment key={index}>
                        <th>מס' כדורים</th>
                        <th>פגיעות</th>
                      </React.Fragment>
                    ))}
                    <th>סה"כ פגיעות</th>
                    <th>ציון על הפגיעות</th>
                  </tr>
                </thead>
                <tbody>
                  {["הכשרה ראשונית", "חידוש רישיון", "רענון תקופתי"].map((type) => {
                    const typeEntries = filteredResults[dateKey].filter(entry => entry.registrationType === type);
                    return typeEntries.length > 0 ? (
                      <React.Fragment key={type}>
                        <tr>
                          <td colSpan="17" style={{ fontWeight: "bold", textAlign: "center" , borderBottom: '1px solid gray'}}>
                            {type}
                          </td>
                        </tr>
                        {typeEntries.map((entry, index) => {
                          const isInitialTraining = entry.registrationType === "הכשרה ראשונית";
                          const bulletCount = isInitialTraining ? 10 : 5;
                          let totalHits = entry.hitLog.reduce((acc, hits) => acc + hits, 0);
                          totalHits = isInitialTraining ? totalHits * 2 : totalHits;
                          const hitScore = (totalHits / (bulletCount * 10)) * 100;

                          return (
                            <tr key={index}>
                              <td>{entry.rangeId}</td>
                              <td>{entry.fullName}</td>
                              <td>{entry.teudatZehut}</td>
                              <td>{entry.imprintOnPistol}</td>
                              <td>{entry.registrationType}</td>
                              {entry.hitLog.map((hits, idx) => (
                                <React.Fragment key={idx}>
                                  <td>{bulletCount === 10 ? "10x10" : "5x5"}</td>
                                  <td>{hits}</td>
                                </React.Fragment>
                              ))}
                              <td>{totalHits}/{bulletCount * 10}</td>
                              <td>{hitScore.toFixed(2)}%</td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    ) : null;
                  })}
                </tbody>
              </table>
            </div>
          ))}
      </div>
        )}
    </div>
  );
};

export default HittingLog;
