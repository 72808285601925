import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import generateAmmoSale from '../PDFFileGenerators/AmmoGen';
import generateGunAmmoSale from '../PDFFileGenerators/gunAndAmmoGen';
import generateCertificate from '../PDFFileGenerators/LocalTrainCertificateGen';
import '../styles/ClientManagementPopup.css';
import { logError } from '../components/firebase.js';

const GunAmmoTrain = ({
  formData,
  setFormData,
  showGunForm,
  setShowGunForm,
  showAmmoForm,
  setShowAmmoForm,
  setShowGunRepForm,
  setShowGunBuyForm,
  showCertificateForm,
  setShowCertificateForm,
  showGunRepForm,
  showGunBuyForm
}) => {
  const sigCanvas = useRef({});
  const [caliber, setCaliber] = useState(formData.ammunitionCaliber || formData.caliber || '');
  const [manufacturer, setManufacturer] = useState(formData.pistolManufacturer || formData.manufacturer || '');
  const [imprintOnPistol, setImprintOnPistol] = useState(formData.imprintOnPistol || '');
  const [model, setModel] = useState(formData.model || '');
  const [origin, setOrigin] = useState(formData.origin || '');
  const [hits, setHits] = useState(formData.hits || 0);
  const [isLoading, setIsLoading] = useState(false);
  const [gunDisposition, setGunDisposition] = useState(''); // For gun buy form dropdown
  const [ammoQuantity, setAmmoQuantity] = useState(''); // For gun buy form additional ammo field

  const handleGenerateGun = async () => {
    setIsLoading(true);
    let signatureUrl = sigCanvas.current?.toDataURL ? sigCanvas.current.toDataURL('image/png') : null;
    if (!signatureUrl) {
      console.error('Signature pad is not available');
      logError(new Error("Signature pad unavailable"), formData.teudatZehut, "GunAmmoTrain.js - handleGenerateGun");
      return;
    }

    const updatedFormData = {
      ...formData,
      caliber,
      manufacturer,
      model,
      origin,
      bulletCount: showGunForm || showGunRepForm ? formData.bulletCount : null, // Only include bullet count for gun forms
      signatureUrl,
      gunDisposition, // Add gunDisposition and ammoQuantity if applicable
      ammoQuantity
    };

    const pdfUrl = await generateGunAmmoSale(updatedFormData);
    setFormData({ ...formData, gunTransferUrl: pdfUrl });
    setShowGunForm(false);
    setIsLoading(false);
  };

  const handleGenerateAmmoSale = async () => {
    setIsLoading(true);
    let signatureUrl = null;
    if (sigCanvas.current && typeof sigCanvas.current.toDataURL === 'function') {
      signatureUrl = sigCanvas.current.toDataURL('image/png');
    } else {
      console.error('Signature pad is not available');
      return;
    }

    const updatedFormData = {
      ...formData,
      caliber,
      signatureUrl,
      bulletCount: formData.bulletCount ,
    };

    const pdfUrl = await generateAmmoSale(updatedFormData);
    setFormData({ ...formData, ammoTransferUrl: pdfUrl });
    setShowAmmoForm(false);
    setIsLoading(false);
  };

  const handleGenerateTrainConfirmation = async () => {
    
    setIsLoading(true);
    let signatureUrl = null;
    if (sigCanvas.current && typeof sigCanvas.current.toDataURL === 'function') {
      signatureUrl = sigCanvas.current.toDataURL('image/png');
    } else {
      console.error('Signature pad is not available');
    }

    const updatedFormData = {
      ...formData,
      signatureUrl,
      caliber,
      manufacturer,
      model,
      hits,
      bulletCount: formData.bulletCount ,
    };

    const pdfUrl = await generateCertificate(updatedFormData);
    setFormData({ ...formData, trainConfirmationUrl: pdfUrl });
    setShowCertificateForm(false);
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading ? (
        <button className="loading-spinner-small" ></button>
      ) : (
        <>
          {/* Show Gun Form */}
          {(showGunForm || showGunRepForm || showGunBuyForm) && (
            <div className="client-detail">
              <h3>שטר מכר - העברת בעלות אקדח {showGunForm ? 'ותחמושת' : ''}</h3>
              <p>יש לעדכן את הנתונים הבאים - להחתים את המוכר</p>
              <div className="details-col">
                <label>
                  יצרן:
                  <input
                    type="text"
                    value={manufacturer}
                    onChange={(e) => setManufacturer(e.target.value)}
                  />
                </label>
                <label>
                  דגם - באנגלית בלבד:
                  <input
                    type="text"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                  />
                </label>
                <label>
                  ארץ ייצור - בעברית בלבד:
                  <input
                    type="text"
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                  />
                </label>
                <label>
                  מספר כלי:
                  <input
                    type="text"
                    value={imprintOnPistol}
                    onChange={(e) => setImprintOnPistol(e.target.value)}
                  />
                </label>
                <label>
                  קוטר קליע:
                  <input
                    type="text"
                    value={caliber}
                    onChange={(e) => setCaliber(e.target.value)}
                  />
                </label>
                {/* Only show bullet amount field for showGunForm */}
                {showGunForm && (
                  <label>
                    כמות כדורים:
                    <input
                      type="number"
                      value={formData.bulletCount}
                      onChange={(e) => setFormData({ ...formData, bulletCount: e.target.value })}
                    />
                  </label>
                )}
                {/* Show additional fields for showGunBuyForm */}
                {showGunBuyForm && (
                  <>
                    <label>
                      בחר אפשרות:
                      <select value={gunDisposition} onChange={(e) => setGunDisposition(e.target.value)}>
                        <option value="">בחר</option>
                        <option value="וויתור על האקדח">וויתור על האקדח</option>
                        <option value="מכירת האקדח">מכירת האקדח</option>
                      </select>
                    </label>
                    <label>
                      האזרח מסר תחמושת בכמות של:
                      <input
                        type="number"
                        value={ammoQuantity}
                        onChange={(e) => setAmmoQuantity(e.target.value)}
                      />
                    </label>
                  </>
                )}
              </div>
              <div className="details-col">
                <p>חתימת הלקוח:</p>
                <SignatureCanvas
                  penColor="#3c6eb4"
                  ref={sigCanvas}
                  canvasProps={{ className: 'sigCanvas' }}
                />
              </div>
              <button className="show-button" onClick={() => sigCanvas.current.clear()}>נקה חתימה</button>
              <button className="show-button" onClick={handleGenerateGun}>צור שטר מכר</button>
              <button className="show-button" onClick={() => setShowGunForm(false)}>ביטול</button>
            </div>
          )}

          {/* Ammo Sale Form */}
          {showAmmoForm && (
            <div className="client-detail">
              <h3>שטר מכר - העברת בעלות תחמושת</h3>
              <p>יש לעדכן כמות תחמושת וקוטר קליע התחמושת הנמכרת - להחתים את הלקוח</p>
              <div className="details-col">
                <label>
                  קוטר קליע:
                  <input
                    type="text"
                    value={caliber}
                    onChange={(e) => setCaliber(e.target.value)}
                  />
                </label>
                <label>
                  כמות כדורים:
                  <input
                    type="number"
                    value={formData.bulletCount}
                    onChange={(e) => setFormData({ ...formData, bulletCount: e.target.value })}
                  />
                </label>
              </div>
              <div className="details-col">
                <p>חתימת הלקוח:</p>
                <SignatureCanvas
                  penColor="#3c6eb4"
                  ref={sigCanvas}
                  canvasProps={{ className: 'sigCanvas' }}
                />
              </div>
              <button className="show-button" onClick={() => sigCanvas.current.clear()}>נקה חתימה</button>
              <button className="show-button" onClick={handleGenerateAmmoSale}>צור שטר מכר</button>
              <button className="show-button" onClick={() => setShowAmmoForm(false)}>ביטול</button>
            </div>
          )}

          {/* Training Confirmation Form */}
          {showCertificateForm && (
            <div className="client-detail">
              <h3>אישור הדרכה במטווח לצורך הוצאת רישיון</h3>
              <p>יש להחתים את הלקוח על תעודת ההדרכה</p>
              <div className="details-col">
                <label>
                  יצרן - באנגלית בלבד:
                  <input
                    type="text"
                    value={manufacturer}
                    onChange={(e) => setManufacturer(e.target.value)}
                  />
                </label>
                <label>
                  דגם - באנגלית בלבד:
                  <input
                    type="text"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                  />
                </label>
                <label>
                  קוטר קליע:
                  <input
                    type="text"
                    value={caliber}
                    onChange={(e) => setCaliber(e.target.value)}
                  />
                </label>
                <label>
                  כמות כדורים:
                  <input
                    type="number"
                    value={formData.bulletCount}
                    onChange={(e) => setFormData({ ...formData, bulletCount: e.target.value })}
                  />
                </label>
                <label>
                  כמות פגיעות מתוך 20 כדורים:
                  <input
                    type="number"
                    value={hits}
                    onChange={(e) => setHits(e.target.value)}
                  />
                </label>
              </div>
              <button className="show-button" onClick={() => sigCanvas.current.clear()}>נקה חתימה</button>
              <button className="show-button" onClick={handleGenerateTrainConfirmation}>צור תעודת הדרכה</button>
              <button className="show-button" onClick={() => setShowCertificateForm(false)}>ביטול</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GunAmmoTrain;
