import React, { useState, useRef } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db, uploadPdfAndGetUrl } from './firebase'; // Import necessary Firebase functions
import { PDFDocument, rgb } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import fontkit from '@pdf-lib/fontkit';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf'; // Load your custom font
import '../styles/GunRecive.css';
import { useNavigate } from 'react-router-dom';
import '../styles/SignaturePad.css';

const GunRecive = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [signatureData, setSignatureData] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        teudatZehut: '',
        phone: '',
        type: 'אקדח',
        manufacturer: '',
        imprintNumber: '',
        file: null,
        date: new Date().toLocaleDateString('he-IL', { timeZone: 'Asia/Jerusalem' })
    });
    const sigCanvas = useRef({}); // Reference for signature pad

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the first uploaded file
    
        if (file && file.type !== 'application/pdf') {  // Check if the file is not a PDF
            alert("הקובץ חייב להיות מסוג PDF בלבד. אנא העלה את אותו הקובץ שקיבלת במייל: 'רישיון זמני'.");
            e.target.value = null; // Clear the input to remove the non-PDF file
            return; // Exit the function early
        }
    
        setFormData({ ...formData, file }); // Update form data if valid
    };
    

    const reverseText = (text) => {
        if (!text) return '';
        return text.split('').reverse().join('');
    };

    const EnglishOrHebrew = (text) => {
        if (!text) return '';
        const hebrewRegex = /[\u0590-\u05FF]/;
        const englishRegex = /[a-zA-Z]/;
        if (hebrewRegex.test(text)) {
            return text;
        } else if (englishRegex.test(text)) {
            return text.split('').reverse().join('');
        } else {
            return '';
        }
    };

    const generatePdfWithUserDetails = async (uploadedPdfBytes, formData, signatureUrl) => {
        const pdfDoc = await PDFDocument.load(uploadedPdfBytes);
        pdfDoc.registerFontkit(fontkit);

        const fontBytes = await fetch(open_sans_font).then(res => res.arrayBuffer());
        const customFont = await pdfDoc.embedFont(fontBytes);
        const reverseText = (text) => {
            if (!text) return '';
            return text.split('').reverse().join('');
        };


        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const date = new Date().toLocaleDateString('he-IL', { timeZone: 'UTC' });

        firstPage.drawText(`אני ${formData.name}, ת.ז. ${reverseText(formData.teudatZehut)}, מספר נייד ${reverseText(formData.phone)}, מצהיר/ה`, { x: 200, y: 105, size: 12, font: customFont, color: rgb(0, 0, 0) });
        firstPage.drawText(`  קבלת האקדח המופיע ברישיון הנ"ל , מתוצרת '${EnglishOrHebrew(formData.manufacturer === 'Other' ? formData.otherOption : formData.manufacturer)}'  `, { x: 200, y: 85, size: 12, font: customFont, color: rgb(0, 0, 0) });
        firstPage.drawText(`ועל קבלת תחמושת בסך של ${reverseText(formData.bulletCount)}`, { x: 200, y: 65, size: 12, font: customFont, color: rgb(0, 0, 0) });
        firstPage.drawText(`בתאריך ${reverseText(date)} ובשעה ${reverseText(new Date().toLocaleTimeString('he-IL'))}, על החתום:`, { x: 200, y: 45, size: 12, font: customFont, color: rgb(0, 0, 0) });
        if (signatureUrl) {
            const signatureImage = await pdfDoc.embedPng(signatureUrl);
            firstPage.drawImage(signatureImage, { x: 30, y: 60, width: 150, height: 50 });
        }

        const pdfBytes = await pdfDoc.save();
        return pdfBytes;
    };

    const validateTeudatZehut = (id) => {
        // Ensure the ID is exactly 9 digits long
        if (id.length !== 9 || isNaN(id)) return false;
      
        let sum = 0;
      
        // Iterate through each digit
        for (let i = 0; i < 9; i++) {
          let digit = parseInt(id[i], 10);
      
          // Alternate weights: multiply by 1 for odd positions, by 2 for even positions
          let weight = i % 2 === 0 ? 1 : 2;
          let product = digit * weight;
      
          // If product is greater than 9, add the sum of the digits (i.e., 12 -> 1 + 2 = 3)
          sum += product > 9 ? product - 9 : product;
        }
        console.log(sum,sum % 10);
        // If the sum is a multiple of 10, the ID is valid
        return sum % 10 === 0;
      }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (
            formData.teudatZehut.length !== 9 || // Checks ID length
            isNaN(formData.teudatZehut) ||       // Checks if ID contains only numbers
            !validateTeudatZehut(formData.teudatZehut) || // Checks if ID passes the validation
            formData.teudatZehut === '000000000'
        ) {
            alert('תעודת זהות חייבת להיות בעלת 9 ספרות ואו מספר תיקני');
            setIsLoading(false);
            return;
        }

        if (!formData.bulletCount || formData.bulletCount === 'none') {
            alert("יש לבחור כמות תחמושת");
            setIsLoading(false);
            return;
        }
        if (!signatureData) {
            alert("יש לחתום על ההצהרה");
            setIsLoading(false);
            return;
        }

        if (!formData.file) {
            alert("יש להעלות קובץ PDF בלבד PDF.");
            setIsLoading(false);
            return;
        }

        try {
            const uploadedPdfBytes = await formData.file.arrayBuffer();
            const generatedPdfBytes = await generatePdfWithUserDetails(uploadedPdfBytes, formData, sigCanvas.current.toDataURL());

            const pdfUrl = await uploadPdfAndGetUrl(generatedPdfBytes, 'firearms', `firearm_${formData.teudatZehut}.pdf`);

            const { file, ...formDataToStore } = formData;

            await setDoc(doc(db, 'firearms', formData.teudatZehut), {
                ...formDataToStore,
                pdfUrl,
            });

            alert("ההרשמה בוצעה בהצלחה - אנא המתן ונקרא לך שהאקדח מוכן לאיסוף");
            navigate('/'); // Redirect to the home page after successful registration
        } catch (error) {
            console.error("Error generating or uploading PDF:", error);
            alert("An error occurred. Please try again.");
        }
        setIsLoading(false);
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
    };

    return (
        <form className="registration-form" onSubmit={handleSubmit}>
            <h2>איסוף נשק</h2>
            <p>אנא מלא את הפרטים הבאים:</p>
            <p>חלה עליך החובה לוודא שהרישיון תואם לנשק שנמסר לך ולנתונים המופיעים ברישיון הזמני</p>
            {/* Form fields */}
            <div className="form-group">
                <label htmlFor="name">שם מלא</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    pattern="[א-ת\s]+"
                    title="שם מלא חייב להכיל אותיות בעברית בלבד"
                />
            </div>
            <div className="form-group">
                <label htmlFor="teudatZehut">תעודת זהות - כולל ספרת ביקורת</label>
                <input
                    type="number"
                    id="teudatZehut"
                    name="teudatZehut"
                    value={formData.teudatZehut}
                    onChange={handleInputChange}
                    required
                    pattern="[0-9]{9}"
                    title="תעודת זהות חייבת להיות בעלת 9 ספרות"
                />
            </div>
            <div className="form-group">
                <label htmlFor="phone">מספר נייד - ללא רווחים או מקפים</label>
                <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    pattern="05[0-9]{8}"
                    title="מספר נייד חייב להתחיל ב-05 ולהיות בעל 10 ספרות"
                />
            </div>
            <p className="info-text">המידע הבא ניתן למצוא ברישיון הזמני</p>
            
            <div className="form-group">
                <label htmlFor="manufacturer">יצרן - החברה אשר מייצרת את כלי הנשק</label>
               
                <select className='register-select' id="manufacturer" name="manufacturer" value={formData.manufacturer} onChange={handleInputChange} required>
                    <option value="none">בחירה</option>
                    <option value="Glock">Glock</option>
                    <option value="Sig Sauer">Sig Sauer </option>
                    <option value="IWI">IWI</option>
                    <option value="Smith & Wesson">Smith & Wesson</option>
                    <option value="CZ">CZ </option>
                    <option value="Beretta">Beretta</option>
                    <option value="Karin">Karin</option>
                    <option value="Walther">Walther</option>
                    <option value="BUL">BUL</option>
                    <option value="FN">FN</option>
                    <option value="Ruger">Ruger</option>
                    <option value="Springfield">Springfield</option>
                    <option value="Shadow System">Shadow Systems</option>
                    <option value="Colt">Colt</option>
                    <option value="Mossberg">Mossberg</option>
                    <option value="HELLCAT">HELLCAT</option>
                    <option value="Taurus">Taurus</option>
                    <option value="Ramon">Ramon</option>
                    <option value="Other">אחר</option>
              </select>
                {/* Conditionally render the input field if "Other" is selected */}
                {formData.manufacturer === 'Other' && (
                    <label>
                    <input
                        className='register-input'
                        type="text"
                        name="otherOption"
                        value={formData.otherOption}
                        onChange={handleInputChange}
                        placeholder="יש להכניס שם היצרן המופיע ברישיון הזמני"
                        required
                    />
            </label>
          )}
            </div>
            
            <div className="form-group">
                <label htmlFor="bulletCount">תחמושת - יש לסמן את הבחירה שנעשתה בעת רכישת האקדח</label>
                <select id="bulletCount" name="bulletCount" value={formData.bulletCount} onChange={handleInputChange}>
                    <option value="none">בחר</option>
                    <option value="0">0</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="file">העלה את הרישיון הזמני שקיבלת במייל (קובץ PDF בלבד) </label>
                <input
                    type="file"
                    id="file"
                    name="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    required
                    placeholder = "יש להעלות את הרישיון הזמני שקיבלת במייל"
                />
            </div>
            <p style={{fontSize:18, margin:15}}>אני {formData.name}, ת.ז {formData.teudatZehut}, מספר נייד {formData.phone}, מצהיר/ה קבלת האקדח
                 המופיע ברישיון הנ"ל, אקדח מתוצרת '{(formData.manufacturer === 'Other' ? formData.otherOption : formData.manufacturer)}' 
                  ועל קבלת תחמושת בסך של {formData.bulletCount} כדורים</p>
            <div className="form-group">
                <label htmlFor="signature">חתימה</label>
                <div className='signature-pad'>
                <SignatureCanvas 
                    penColor="#3c6eb4" 
                    willreadfrequently={true}
                    canvasProps={{className: 'sigCanvas'}} 
                    ref={sigCanvas} 
                    onEnd={() => setSignatureData(sigCanvas.current.toDataURL())}
                />
                </div>
            </div>
            {isLoading ? (
                <button className="confirm-button" >טוען...</button>
            ) : (
            <div className="actions">
                <button className='clear-button' type="button" onClick={clearSignature}>נקה חתימה</button>
                <button type="submit" className="confirm-button">אישור</button>
            </div>
            )}
        </form>
    );
};

export default GunRecive;
